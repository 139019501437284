.square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 50px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 50px;
    border-radius: 10%;
    user-select: none;
}

.square-completed {
    background: yellow
}


@media (hover:hover) {

    .square-editable:hover {
        background: orange
    }
}

@media (hover:none) {
    .square-editable:active {
        background: orange
    }
}

.board-row:after {
    clear: both;
    content: '';
    display: table;
}

.game {
    display: grid;
    place-items: center;
}