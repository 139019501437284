@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid orange;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.loading-center {
    display: grid;
    place-items: center;
}

.loading-text {
    font-size: 40px;
    font-weight: bold;
    color: orange;
    user-select: none;
}