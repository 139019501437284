.whatsapp-share-button {
    background-color: #128C7E;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5%;
    font-weight: bold;
    user-select: none;
}

.whatsapp-share-button:hover {
    background-color: #25d366;
}

.fa-whatsapp {
    color: #fff;
    background: linear-gradient(#25d366, #25d366) 14% 84%/16% 16% no-repeat, radial-gradient(#25d366 60%, transparent 0);
}