.ready-center {
    display: grid;
    place-items: center;
    grid-gap: 10px;
}

.ready-text {
    font-size: 40px;
    font-weight: bold;
    color: orange;
    user-select: none;
}

.go-button {
    background-color: orange;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 5%;
    font-weight: bold;
    user-select: none;
}

.go-button:hover {

    background-color: rgb(255, 197, 7);
}